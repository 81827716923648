<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        加入我们
      </div>
      <div class="topic_desc_en">
        JOIN US
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <!-- <div class="panel_content">
        <div class="img_content">
          <img src="./images/jrwm.png">
        </div>
        <div class="text_content">
          <div class="staff_info">
            扫码获得最新招聘信息
          </div>
        </div>
      </div> -->
      <div class="staff_panel">
        <div class="staff_item" :class="[{
          'staff_item_active':idx===currentIdx
        }]" v-for="(item,idx) in dataInfos" :key="idx">
          <div class="staff_header" @click="currentIdx===idx?currentIdx=null:currentIdx=idx">
            <div class="header_line"></div>
            <div class="header_label">
              {{item.name}}
            </div>
          </div>
          <div class="staff_detail">
            <div class="detail_item" v-for="(dItem,dIdx) in item.detail" :key="dIdx">
              <div class="detail_item_label">
                {{dItem.label}}
              </div>
              <div class="detail_item_value" v-for="(vItem,vIdx) in dItem.values" :key="vIdx">
                <p>
                  {{vItem}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      currentIdx:0,// 当前展示的id
      dataInfos:[],
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      let lang = this.$i18n.locale;
      return this.infoData[lang] || "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async getDataInfos () {
      const { data } = await this.$http.get(`${window.SEVERURL}/safeglp/generator/jobs/list?page=1&limit=999`);
      this.dataInfos = data.page.list.map(i=>{
        return {
          name:i.jobTitle,
          detail:[
            {
              label:"岗位职责",
              values:i.responsibilities.split("\n")
            },
            {
              label:"任职要求",
              values:i.requirements.split("\n")
            },
            {
              label:"工作地点",
              values:[
                i.location
              ]
            },
            {
              label:"简历投递",
              values:[
                "简历请投递邮箱：recruit@safeglp.com "
              ]
            }
          ]
        }
      })
    },
    // 获取图片
    getPic(name){
      return require(`./images/${name}.png`)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getDataInfos();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topic_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 66px;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 42px;
      color: #222222;
      line-height: 46px;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-bottom: 50px;
    .panel_content{
      display: flex;
      align-items: center;
      .img_content{
        width: 172px;
        img{
          width: 100%;
          height: auto;
        }
      }
      .text_content{
        margin-left: 39px;
        .staff_info{
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 24px;
          color: #333333;
          line-height: 44px;
        }
        .contact_info{
          font-family: PingFang SC, PingFang SC;
          font-weight: normal;
          font-size: 18px;
          color: #333333;
          line-height: 33px;
          margin-top: 20px;
        }
      }
    }
    .staff_panel{
      width: 100%;
      .staff_item{
        margin-bottom: 2px;
        &.staff_item_active{
          .staff_detail{
            height: unset;
          }
        }
        .staff_header{
          height: 62px;
          background: #F6F7F9;
          box-shadow: 0px 1px 4px 0px #D2DAEA;
          display: flex;
          align-items: center;
          cursor: pointer;
          .header_line{
            margin-right: 26px;
            height: 100%;
            width: 4px;
            background:  linear-gradient(to bottom, rgba(0, 134, 209, 1), rgba(152, 218, 255, 1));
          }
          .header_label{
            flex: 1;
            overflow: hidden;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI;
            font-weight: bold;
            font-size: 20px;
            color: #0086D1;
          }
        }
        .staff_detail{
          height: 0;
          overflow: hidden;
          transition: all 0.3s linear 0s;
          background: #FFFFFF;
          box-shadow: 0px 1px 4px 0px #D2DAEA;
          box-sizing: border-box;
          .detail_item{
            margin: 0 37px;
            margin-bottom: 30px;
            &:last-child{
              margin-bottom: 30px;
            }
            &:first-child{
              margin-top: 30px;
            }
            .detail_item_label{
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: bold;
              font-size: 20px;
              color: #444B64;
              line-height: 24px;
              margin-bottom: 30px;
            }
            .detail_item_value{
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-size: 14px;
              color: #444B64;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
</style>